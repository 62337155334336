"use strict";
window.z = window.z || {};

z.AppRouter = Backbone.Router.extend({
    routes:{
        "resellers/add" : "addReseller",
        "resellers/edit/:id" : "editReseller",
        "extraservices/add" : "addExtraServices",
        "extraservices/edit/:id" : "editExtraServices",
        "users/add" : "addUser",
        "users/edit/:id" : "editUser",
        "vendors/add" : "addVendor",
        "vendors/edit/:id" : "editVendor",
        "price-rules/add": "addPriceRule",
        "price-rules/edit/:id" : "editPriceRule",
        "services/add" : "addService",
        "services/edit/:id" : "editService",
        "services/configure/:id" : "configureService",
        "vendors/service-worksites/:vendor/add" : "addServiceWorksiteAssoc",
        "vendors/service-worksites/:vendor/edit/:id" : "editServiceWorksiteAssoc",
        "notifications/edit/:id" : "editNotification",
        "tariff-vendor/:vendor/add" : "addTariffVendor",
        "tariff-vendor/:vendor/edit/:id" : "editTariffVendor",
        "tariff-vendor-admin/add" : "addTariffVendorAdmin",
        "tariff-vendor-admin/edit/:id" : "editTariffVendorAdmin",
        "tariff-reseller-admin/edit/:id" : "editTariffResellerAdmin",
        "tariff-reseller-admin/add" : "addTariffResellerAdmin",
        "tariff-reseller/:reseller/add" : "addTariffReseller",
        "tariff-reseller/:reseller/edit/:id" : "editTariffReseller",
        "tariff-vendor-promotion/:promotion/add" : "addTariffPromotion",
        "tariff-vendor-promotion/:promotion/edit/:id" : "editTariffPromotion",
        "tariff-reseller-promotion/:promotion/add" : "addTariffPromotion",
        "tariff-reseller-promotion/:promotion/edit/:id" : "editTariffPromotion",
        "discounts/edit/:id" : "editDiscount",
        "discounts/approve/:id" : "approveDiscount",
        // "feedback" : "postAction",
    }
});

z.router = new z.AppRouter();