"use strict";

function calculateTotal()
{
    const table = document.getElementById('orderExtraInvoiceForm-list-grid');

    let totalSum = 0;
    let totalGross = 0;
    let vat = 19;

    for (let i = 1; i < table.rows.length; i++) {
        const cellValue = table.rows[i].cells[3].innerText;
        const numericValue = parseFloat(cellValue);

        if (!isNaN(numericValue)) {
            totalSum += numericValue;
        }
    }

    totalGross = parseFloat((parseFloat(totalSum) * (100 + parseFloat(vat)) / 100))

    $("#total_not_tva").text(totalSum);
    $("#total_tva").text(totalGross);

}

function deleteRow(buttonElement) {

    const rowElement = $(buttonElement).closest('tr');
    if (confirm('Sigur doriti sa stergeti acest rand?')) {
        rowElement.remove();

    }
    calculateTotal();
}


(function(z, Backbone, _){

    z.OrderExtraInvoice = Backbone.View.extend({

        el: "#order-extra-invoice-section",

        /**
         * The default email for the client data
         */
        DEFAULT_EMAIL: 'nuare@depanero.ro',

        /**
         * URL for taking the ANAF details about a company based on the fiscal code
         */
        GET_ANAF_DETAILS_URL: '/api/clients/details/{fiscalCode}',

        events: {
            'click #add-extra-invoice-modal-btn': '_displayExtraInvoiceModalForm',
            'click .close-modal-link' : '_hideClientModalForm',
            'change #orderextrainvoiceform-name-field': '_onExtraServiceChange',
            'click #orderclientform-withoutEmail-field': '_onWithoutEmailCheck',
            "click #orderextrainvoiceform-add-button": "_addToTable",
            "click #orderextrainvoice-save-button": "_onGeneratingInvoice",
            'click .orderextrainvoice-preview-invoice-btn': '_onPreviewingExtraInvoice',
            'click .orderinvoice-send-extrainvoice-btn': '_onSendingExtraInvoice',
        },

        /**
         * Url from where we can download the invoice
         */
        EXTRA_INVOICE_DOWNLOAD_URL: '/api/orders/{hash}/extrainvoice/{invoiceNumber}',

        /**
         * Url for the generating invoice action
         */
        GENERATE_EXTRA_INVOICE_URL: '/api/orders/{order}/extrainvoice',

        /**
         * Url from where we can download the attachment
         */
        ATTACHMENT_DOWNLOAD_URL: '/attachments/{attachment}/download',

        /**
         * Url for the sending proforma action
         */
        SEND_EXTRA_INVOICE_URL: '/orders/extrainvoicesent/{invoiceNumber}',


        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            this.$modalElement = this.$('#orderextrainvoiceform-modal');
            this.$errorMsg = this.$el.find('#orderextrainvoiceform-error-panel');
            this.order = z.boot.order;


            Backbone.Validation.bind(this, {
                // valid   : this._onValid.bind(this),
                // invalid : this._onInvalid.bind(this),
                // model   : this.model
            });

            // this.setEvents();

            // this._initializeChosen();
        },


        /**
         * Binds the view to the supplied collection
         * @param {object} collection The collection to bind to
         * @return {undefined}
         * @private
         */
        _bindCollection: function(collection){

            // remove previous listeners
            this.stopListening(this.collection);

            // set the new collection
            this.collection = collection;

            this.listenTo(this.collection, 'update',    this._onCollectionUpdate);
            this.listenTo(this.collection, 'reset',     this._onCollectionReset);
            this.listenTo(this.collection, 'request',   this._onCollectionRequest);
            this.listenTo(this.collection, 'sync',      this._onCollectionSync);
            this.listenTo(this.collection, 'error',     this._onCollectionError);
        },
        /**
         * Method that displays the modal window.
          *
         * @return {undefined}
         * @private
         */
        _displayExtraInvoiceModalForm: function() {

            this.$("#orderextrainvoice-save-button").removeClass('disabled');
            this.$modalElement.modal("show");

        },

        _hideClientModalForm:function () {
            this._isLoadingGenerateExtraInvoiceBtn(false);
            this._getPaymentTypeBtnEl().val('');
            this.$modalElement.modal("hide");
        },


        /**
         * Display the error message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showExtraError: function(message){

            this._hideExtraSuccess();

            var $error = this.$('#extraInvoice-error-message');

            $error
                .text(message)
                .removeClass('hidden')
                .fadeOut(0)
                .fadeIn('slow')
                .delay(5000)
                .fadeOut('slow', function(){

                    $error.addClass('hidden');
                });
        },

        /**
         * Hide the success message
         * @return {undefined}
         * @private
         */
        _hideExtraSuccess: function(){

            var $error = this.$('#extraInvoice-success-message');

            $error
                .finish()
                .fadeOut(0)
                .addClass('hidden');
        },

        /**
         * Hide the error message
         * @return {undefined}
         * @private
         */
        _hideExtraError: function(){

            var $error = this.$('#extraInvoice-error-message');

            $error
                .finish()
                .fadeOut(0)
                .addClass('hidden');
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @return {undefined}
         * @private
         */
        _showExtraSuccess: function(message){

            this._hideExtraError();

            var $success = this.$('#extraInvoice-success-message');

            $success
                .html(message)
                .removeClass('hidden')
                .fadeOut(0)
                .fadeIn('slow')
                .delay(5000)
                .fadeOut('slow', function(){

                    $success.addClass('hidden');
                });
        },


        /**
         * Method that resets form fields
         *
         * @private
         * @return {undefined}
         */
        _resetForm: function() {

            this._toggleFormCancelBtn(false);

            this._setFormSaveBtnText(Translator.trans('js.btn_add'));
        },

        /**
         * Resets the validation state of the fields
         * @return {undefined}
         * @private
         */
        _resetValidation: function(){

            var $fields = this.$('[data-validation]');

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Retrieves the data from the form
         * @return {object} The form data
         * @private
         */
        _getFormRecord: function () {

            var $nameField = this.getEl('field.name');
            var $typeField = this.getEl('field.type');
            var $overridesField = this.getEl('field.overridesBasePrice');
            var $increaseField = this.getEl('field.increaseType');

            var options = z.serviceOptionsForm.collection.toJSON();

            return {
                name: $nameField.val(),
                type: $typeField.val(),
                overridesBasePrice: parseInt($overridesField.val()),
                increaseType: parseInt($increaseField.val()),
                options: options
            };
        },

        /**
         * Retrieves the cached element from selectors
         * @param {string} key The key
         * @param {bool} fresh Should we fetch it again
         * @returns {*} The element or undefined
         */
        getEl: function(key, fresh){

            if (_.isUndefined(key) || key === 'el') {
                return this.$el;
            }

            var selectors = _.result(this, 'selectors');

            if (!selectors) {
                return null;
            }

            var cache = this.elsCache = this.elsCache || {};

            if (!_.has(cache, key) || fresh) {

                var selector = _.isFunction(selectors[key]) ? selectors[key].call(this) : selectors[key];

                var result = null;

                if (selector) {

                    var $query = $(selector); // result of $($($('.class'))) is the same as $('.class')

                    result = $query.length ? $query : null;
                }

                cache[key] = result;
            }

            return cache[key];
        },

        _onExtraServiceChange: function() {

            const id = this.$('#orderextrainvoiceform-name-field').val();

            // Trimite un request POST către backend pentru a obține prețul
            fetch('/get-price', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id: id }),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
                })
                .then(data => {
                    if (data.error) {
                        document.getElementById("orderextrainvoiceform-price-field").textContent = "Eroare: " + data.error;
                    } else {
                        document.getElementById("orderextrainvoiceform-price-field").value = data.price;
                        document.getElementById("orderextrainvoiceform-price-name-field").value = data.name;
                        document.getElementById("orderextrainvoiceform-price-id-field").value = data.id;
                        document.getElementById("orderextrainvoiceform-price-sapId-field").value = data.sapId;
                    }
                })
                .catch(error => {
                    console.error('There was a problem with the fetch operation:', error);
                });
        },

        _addToTable() {
            const id = this.$('#orderextrainvoiceform-name-field').val();
            const price = this.$('#orderextrainvoiceform-price-field').val();
            const name = this.$('#orderextrainvoiceform-price-name-field').val();
            const idSrv = this.$('#orderextrainvoiceform-price-id-field').val();
            const sapId = this.$('#orderextrainvoiceform-price-sapId-field').val();
            const quantity = this.$('#orderextrainvoiceform-quantity-field').val();


            if (!id || !price || !quantity) {
                this._showExtraError('Completați toate câmpurile!');
                this._isLoadingGenerateExtraInvoiceBtn(false);
                return;
            }

            if (!$.isNumeric(quantity) || quantity <= 0 ){
                this._showExtraError('Cantitatea trebuie sa fie un numar mai mare ca 0!');
                this._isLoadingGenerateExtraInvoiceBtn(false);
                return;
            }

            if (!$.isNumeric(price) || price <= 0 ){
                this._showExtraError('Pretul trebuie sa fie un numar mai mare ca 0!');
                this._isLoadingGenerateExtraInvoiceBtn(false);
                return;
            }

            const table = document.getElementById('orderExtraInvoiceForm-list-grid').getElementsByTagName('tbody')[0];
            const newRow = table.insertRow();


            const nameCell = newRow.insertCell(0);
            const quantityCell = newRow.insertCell(1);
            const priceCell = newRow.insertCell(2);
            const totalCell = newRow.insertCell(3);
            const actionCell = newRow.insertCell(4);
            const idCell = newRow.insertCell(5);
            idCell.hidden = true;
            const sapIdCell = newRow.insertCell(6);
            sapIdCell.hidden = true

            nameCell.innerHTML = name;
            quantityCell.innerHTML = "<span style='align-content: center'>" + quantity + "</span>";
            priceCell.innerHTML = "<span style='align-content: center'>" + price + "</span>";
            totalCell.innerHTML = "<span style='align-content: center'>" + (quantity * price).toFixed(2) + "</span>";
            this._calculateTotal();
            actionCell.innerHTML = '<a onclick="deleteRow(this)" class="btn btn-danger btn-xs delete-param" style="float: right;"><i class="fa fa-trash"></i></a>';
            idCell.innerHTML = id;
            sapIdCell.innerHTML = sapId;


                this.$('#orderextrainvoiceform-name-field').val("");
                this.$('#orderextrainvoiceform-price-field').val("");
                this.$('#orderextrainvoiceform-quantity-field').val("1");
        },

        _deleteRow() {

            this._calculateTotal();
            this.remove();
        },

        _calculateTotal: function ()
        {
            const table = document.getElementById('orderExtraInvoiceForm-list-grid');

            let totalSum = 0;
            let totalGross = 0;
            let vat = 19;

            for (let i = 1; i < table.rows.length; i++) {
                const cellValue = table.rows[i].cells[3].innerText;
                const numericValue = parseFloat(cellValue);

                if (!isNaN(numericValue)) {
                    totalSum += numericValue;
                }
            }

             totalGross  = (parseFloat(totalSum) * (100 + parseFloat(vat)) / 100);

            $("#total_not_tva").text(totalSum.toFixed(2));
            $("#total_tva").text(totalGross.toFixed(2));

        },

        /**
         * On generating the invoice
         * @returns {undefined}
         * @private
         */
        _onGeneratingInvoice: function() {
            event.preventDefault();

            this.$("#orderextrainvoice-save-button").addClass('disabled');
            this._generateInvoice( function(response, status) {

                // Get the response
                var res = JSON.parse(response.responseText);
                var invoiceNumber = res.data;

                // // If there were errors when generating the invoice
                if (status === 'error') {
                    this.$("#orderextrainvoice-save-button").removeClass('disabled');
                    this._showExtraError(response);

                    // Stop the loading for the generate invoice button
                    this._isLoadingGenerateExtraInvoiceBtn(false);

                    return;
                }
                else
                {
                    this._isLoadingGenerateExtraInvoiceBtn(true);
                    this._getPaymentTypeBtnEl().val('');
                }

                // Stop the loading for the generate invoice button
                this._isLoadingGenerateExtraInvoiceBtn(false);

            });

        },

        /**
         * Get generating invoice action URL
         * @returns {string} send proforma action URL
         * @private
         */
        _getGeneratingInvoiceUrl: function() {
            var order = z.boot.order;
            var extraItemsAndInvoice = this._insertItems();

            if (!extraItemsAndInvoice)
            {
                this.$("#orderextrainvoice-save-button").removeClass('disabled');
                return false;
            }
            var url = this.GENERATE_EXTRA_INVOICE_URL.replace('{order}', order.id);

            $.ajax({
                url: url,
                method: 'POST',
                data: { invoiceData: extraItemsAndInvoice }, // Trimite datele
                success: (response) => {
                    // location.reload();
                    // this._showExtraSuccess('Datele au fost salvate cu succes!')
                    setTimeout(function () {
                        location.reload();
                    },2000);
                },
                error: (xhr, status, error) => {
                    this._isLoadingGenerateExtraInvoiceBtn(false);
                    this.$("#orderextrainvoice-save-button").removeClass('disabled');
                    this._showExtraError('A apărut o eroare la salvarea datelor!');
                }
            });
        },

        /**
         * Enables or disables the loading animation
         * @param {bool} loading Should show or hide the animation
         * @param {object} $btn Button object
         * @return {undefined}
         * @private
         */
        _isLoading: function (loading, $btn) {

            loading = loading ? loading : _.isUndefined(loading);

            var ladda = $btn.data('ladda') ? $btn.data('ladda') : $btn.ladda().data('ladda');

            if (loading) {

                ladda.start();

            } else {

                ladda.stop();
            }
        },


        /**
         * Loading animation for generate invoice btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingGenerateExtraInvoiceBtn: function(loading) {

            var $generateExtraInvoiceBtnEl = $('#orderextrainvoice-save-button');
            this._isLoading(loading, $generateExtraInvoiceBtnEl);
        },


        /**
         * Generate invoice action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _generateInvoice: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getGeneratingInvoiceUrl();

            if (url){

                this._makeRequest(url, callback);
            }

        },

        /**
         * Handle on previewing invoice
         * @param {Event} e the event
         * @private
         * @return {undefined}
         */
        _onPreviewingExtraInvoice: function(e) {
            e.preventDefault();
            var invoiceNumber = $(e.currentTarget).attr('data-id');
            // Download the invoice
            this._downloadInvoice( function(response, status) {

                // If there were errors when downloading the invoice
                if (status === 'error') {

                    // this._displayError(response);

                    // Stop the loading for the preview invoice button
                    this._isLoadingGenerateExtraInvoiceBtn(false);

                    return;
                }

                var res = JSON.parse(response.responseText);
                var attachment = res.data;

                if (attachment && attachment.hasOwnProperty('id')) {

                    window.location = this._getAttachmentDownloadUrl(attachment);
                } else {

                    this._setErrorMsg(Translator.trans('js.error_message_orderinvoice_not_generated_error'));
                    return;
                }

                // Stop the loading for the preview invoice button
                this._isLoadingGenerateExtraInvoiceBtn(false);

            }, invoiceNumber);
        },


        /**
         * Returns the url from where you can download the invoice attachment
         * @param {Object} attachment the invoice attachment
         * @return {string} the url
         * @private
         */
        _getAttachmentDownloadUrl: function(attachment) {

            return this.ATTACHMENT_DOWNLOAD_URL.replace('{attachment}', attachment.id);
        },

        /**
         * Loading animation for preview invoice btn
         * @param {bool} loading Should show or hide the animation
         * @returns {undefined}
         * @private
         */
        _isLoadingPreviewInvoiceBtn: function(loading) {

            var $previewInvoiceBtnEl = this._getPreviewInvoiceBtnEl();
            this._isLoading(loading, $previewInvoiceBtnEl);
        },

        /**
         * Get preview invoice btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getPreviewInvoiceBtnEl: function() {
            return $('#orderextrainvoice-save-button');
        },

        /**
         * Get preview invoice btn element
         * @returns {object} the send proforma btn el
         * @private
         */
        _getPaymentTypeBtnEl: function() {
            return $("#orderextrainvoiceform-extraPaymentType-field");
        },

        /**
         * Generate invoice action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _downloadInvoice: function(callback, invoiceNumber) {
            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getInvoiceDownloadUrl(invoiceNumber);

            this._makeRequest(url, callback, 'GET');

        },

        /**
         * Returns the url from where you can download the invoice
         * @return {string} The url
         * @private
         */
        _getInvoiceDownloadUrl: function(invoiceNumber) {

            var order = z.boot.order;

            return this.EXTRA_INVOICE_DOWNLOAD_URL.replace('{hash}', order.hash).replace('{invoiceNumber}', invoiceNumber);
        },

        /**
         * Make ajax requests
         * @param {string} url request URL
         * @param {Function} callback callback funcyopm
         * @param {string} method request method
         * @returns {undefined}
         * @private
         */
        _makeRequest: function(url, callback, method) {

            callback = _.isFunction(callback) ? callback : _.noop;
            method = _.isUndefined(method) ? 'POST' : method;

            var opts = {
                method      : method,
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function() {

                callback.apply(this, arguments);

            }.bind(this), opts);
        },

        _insertItems: function () {

            var order = z.boot.order;
            var data = [];

            var total_not_tva = $("#total_not_tva").text();
            var invoiceType = $("#orderextrainvoiceform-extraPaymentType-field").val();

            // var globalItems = {invoiceNumber:invoiceNumber, totalNotTva:total_not_tva, invoiceType:invoiceType, orderId:order.id};
            var globalItems = { totalNotTva:total_not_tva, invoiceType:invoiceType, orderId:order.id};

            data.push({ globalItems: globalItems });


            $('#orderExtraInvoiceForm-list-grid tbody tr').each(function() {
                var row = $(this);
                var rowData = {
                    nume: row.find('td').eq(0).text(), // Nume
                    cant: row.find('td').eq(1).text(),  // Cantitate
                    pret: row.find('td').eq(2).text(),  // Pret
                    total: row.find('td').eq(3).text(),  // Total
                    id: row.find('td').eq(5).text(),  // id
                    sapId: row.find('td').eq(6).text()  // sapId
                };
                data.push(rowData);
            });

            if (invoiceType == '')
            {
                this._showExtraError('Alege modalitatea de plata');
                // Stop the loading for the preview invoice button

                return false;
            }
            this._isLoadingGenerateExtraInvoiceBtn(true);
            return data;
        },

        /**
         * On sending proforma event
         * @param {Event} e the event
         * @returns {undefined}
         * @private
         */
        _onSendingExtraInvoice: function(e) {

            var invoiceNumber = $(e.currentTarget).attr('data-id');
            // Send the payment link
            this._sendExtraInvoice( function() {

                // Update the comment section
                $(document).trigger("comments.updateComments");
                setTimeout(function () {
                    location.reload();
                },2);
            },invoiceNumber );
        },

        /**
         * Get sending proforma action URL
         * @returns {string} send proforma action URL
         * @private
         */
        _getSendingExtraInvoiceUrl: function(invoiceNumber) {
            var order = z.boot.order;

            return this.SEND_EXTRA_INVOICE_URL.replace('{invoiceNumber}', invoiceNumber);
        },

        /**
         * Send payment link action
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _sendExtraInvoice: function(callback, invoiceNumber) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getSendingExtraInvoiceUrl(invoiceNumber);

            this._makeRequest(url, callback);

        },

    });
})(window.z = window.z || {}, Backbone, _);