"use strict";

(function(z, Backbone, _){

    z.OrderClient = Backbone.View.extend({

        el: "#order-invoice-section, #order-extra-invoice-section",

        /**
         * The default email for the client data
         */
        DEFAULT_EMAIL: 'nuare@depanero.ro',

        /**
         * URL for taking the ANAF details about a company based on the fiscal code
         */
        GET_ANAF_DETAILS_URL: '/api/clients/details/{fiscalCode}',

        events: {
            'submit .modal-form#orderclient-modal-form': '_onSubmitForm',
            'click #orderinvoice-client-modal-btn': '_displayClientModalForm',
            'click .close-modal-link' : '_hideClientModalForm',
            'change #orderclientform-county-field': '_onCountyChange',
            'click #orderclientform-type-field': '_onTypeCheck',
            'click #orderclientform-withoutEmail-field': '_onWithoutEmailCheck',
            'click #orderclient-search-button': '_onSearchingFiscalCode',
            'keydown #orderclientform-fiscalCode-field': '_onFiscalCodeChange',
        },

        /**
         * Initialize method
         * @return {undefined}
         */
        initialize: function() {

            this.$modalElement = this.$('#orderclientform-modal');
            this.$errorMsg = this.$el.find('#orderclientform-error-panel');
            this.order = z.boot.order;

            // Adding collections to view
            this.localitiesCollection = new z.LocalitiesCollection();

            // Listening on collection events
            this.listenTo(this.localitiesCollection, 'add', this._onLocalitiesCollectionAdd);
            this.listenTo(this.localitiesCollection, 'reset', this._onLocalitiesCollectionReset);

            Backbone.Validation.bind(this, {
                valid   : this._onValid.bind(this),
                invalid : this._onInvalid.bind(this),
                model   : this.model
            });

            this.$('#orderclient-company-field').attr('disabled', true);

            this.setEvents();

            this._initializeChosen();
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function(){

            this._removeErrorMsg();
            this._fillForm();
            this._initializeChosen();

            return this;
        },

        /**
         * Method that prepares the model to be saved
         *
         * @param {Object} model The model
         * @private
         * @return {undefined}
         */
        _prepareModel: function(model)
        {
            model.set('customer', this._getCustomerId());
        },

        /**
         * Retrieves the customer id if it is available
         * @returns {number|null} The customer id
         * @private
         */
        _getCustomerId: function(){

            return this.order.customer.id;
        },

        /**
         * Get customer
         * @return {Object} the customer
         * @private
         */
        _getCustomer: function() {

            return this.order.customer;
        },

        /**
         * Get client
         * @return {Object} the client
         * @private
         */
        _getClient: function() {

            return this.order.customer.client;
        },

        /**
         * Setting the events on the model.
         *
         * @return {undefined}r
         */
        setEvents: function(){

            // On success
            this.listenTo(this.model, 'success', function() {

                var successMessage = Translator.trans('js.order_invoice_client_success_message');

                // Hide the modal
                this._hideClientModalForm();

                // Stop the loading
                this._isLoading(false, this.$("#orderclient-save-button"));

                // Update client details
                this._updateClientDetails();

                // Update client label texts
                this._updateClientLabels();

                // Show the success mesasage
                this._showSuccess(successMessage);

                // Trigger client success event
                $(document).trigger('client.success');

                // Trigger comments section update
                $(document).trigger("comments.updateComments");

            }.bind(this));

            // On error
            this.listenTo(this.model, 'error', function(model, res) {

                this._displayError(res);

                this._isLoading(false, this.$("#orderclient-save-button"));

            }.bind(this));
        },

        /**
         * Initialiaze chosen fields
         * @private
         * @return {undefined}
         */
        _initializeChosen: function() {

            this.$('.chosen-select').trigger("chosen:updated");
            this.$('.chosen-select').chosen({width: "100%"});
        },

        /**
         * Fields validation for the form
         *
         * @param {Object} attr Attributes that are being validated
         * @return {undefined}
         */
        dataValidation: function(attr) {

            var $fields = this.$('[data-validation]');

            if ( attr ){
                $fields = this.$('[data-validation~="' + attr + '"]');
            }

            $fields
                .removeClass('has-error')
                .find('.help-block')
                .addClass('hidden')
                .text('');
        },

        /**
         * Method that is called if the form is valid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @private
         * @return {undefined}
         */
        _onValid: function(view, attr) {
            this.dataValidation(attr);
        },

        /**
         * Method that is called if the form is invalid.
         *
         * @param {Object} view - the view being passed
         * @param {Object} attr - attributes that are being validated
         * @param {Object} error - validation errors
         * @private
         * @return {undefined}
         */
        _onInvalid: function(view, attr, error) {
            this.$('[data-validation~="' + attr + '"]')
                .addClass('has-error')
                .find('.help-block')
                .removeClass('hidden')
                .text(error);
        },

        /**
         * Method triggered on the locality collection add
         *
         * @param {object} model - Model binded to the collection
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionAdd: function(model) {

            this._renderLocality(model);
        },

        /**
         * Method triggered on the locality collection reset
         *
         * @private
         * @return {undefined}
         */
        _onLocalitiesCollectionReset: function() {

            this._renderLocalityReset();
        },

        /**
         * Method that renders the locality render
         *
         * @param {object} model - Model bound to the collection
         * @private
         * @return {undefined}
         */
        _renderLocality: function(model) {

            var $option = $('<option>').attr('value', model.get('id')).text(model.get('name'));
            this.$('#orderclientform-locality-field').append($option);
        },

        /**
         * Method that resets the locality select
         *
         * @private
         * @return {undefined}
         */
        _renderLocalityReset: function() {

            var $option = this.$("#orderclientform-locality-field option:eq(0)").detach();
            this.$('#orderclientform-locality-field').empty().append($option);
        },

        /**
         * Method that resets the county select
         *
         * @private
         * @return {undefined}
         */
        _renderCountyReset: function() {

            var $option = this.$("#orderclientform-county-field option:eq(0)").detach();
            this.$('#orderclientform-county-field').empty().append($option);
        },

        /**
         * Method that makes the request to get the data
         * for the localities select
         *
         * @private
         * @return {undefined}
         */
        _onCountyChange: function () {

            // Get the county id
            var id = this.$("#orderclientform-county-field option:selected").val();

            this.localitiesCollection.reset(null);

            if (id) {

                this.localitiesCollection.setCountyId(id);

                // Get the localities collection for the given county
                this.localitiesCollection.fetch({
                    success: function() {
                        this.$('#orderclientform-locality-field').trigger("chosen:updated");
                        this.$("#orderclientform-locality-field").trigger('load');
                    }.bind(this)
                });

            } else {

                this.$('#orderclientform-locality-field').trigger("chosen:updated");
            }
        },

        /**
         * Update client details
         *
         * @private
         * @return {undefined}
         */
        _updateClientDetails: function() {

            var $clientNameEl = this.$('#order-client-name');

            // Get the appropriate name (name or company name)
            $clientNameEl.text(this.model.get('label'));
        },

        /**
         * Update client btn
         *
         * @private
         * @return {undefined}
         */
        _updateClientLabels: function() {

            // todo add all these selectors into separate methods
            var $clientBtnEl = this.$('#orderinvoice-client-modal-btn');
            var $clientModalTitleEl = this.$('#orderclientform-modal-title');

            // Get the appropriate name (name or company name)
            $clientBtnEl.text(Translator.trans('js.btn_edit_order_invoice_client'));
            $clientModalTitleEl.text(Translator.trans('js.btn_edit_order_invoice_client'));
        },

        /**
         * Method that submits the form.
         *
         * @param {Event} e - submit event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function(e) {

            e.preventDefault();

            var formData = this._getFormData();

            this.model.set(formData);
            this._prepareModel(this.model);

            if (!this.model.isValid(true)) {
                return;
            }

            this._isLoading(true, this.$("#orderclient-save-button"));

            this.model.save(null,{

                success: function(i) {

                    // Update z.boot.order.client
                    z.boot.order.customer.client = this.model.attributes;
                    this.order = z.boot.order;

                    this.model.trigger("success", i);

                }.bind(this)

            });
        },

        /**
         * Event for changing the client type
         * @return {undefined}
         * @private
         */
        _onTypeCheck: function() {

            this._updateInfoOnTypeCheck(true);
        },

        /**
         * Update info on type check
         * @param {undefined|bool} isTypeCheckEvent true if the method is called from the type check event
         * @return {undefined}
         * @private
         */
        _updateInfoOnTypeCheck: function(isTypeCheckEvent) {

            isTypeCheckEvent = !_.isUndefined(isTypeCheckEvent);

            // todo add all these selectors into separate methods
            var $typeEl = this.$('#orderclientform-type-field');
            var $companyTypeEl = this.$('#client-company-section');
            var isChecked = $typeEl.length ? $typeEl.is(':checked') : false;

            this.$("#orderclient-save-button").attr('disabled', false);

            if (isChecked) {

                // Disable the address field
                this.$("#orderclientform-address-field").attr('disabled', true);

                this._toggle($companyTypeEl, true, true);

            } else {

                // Enable the address field
                this.$("#orderclientform-address-field").attr('disabled', false);

                this._toggle($companyTypeEl, true, false);
                this._resetCompanySectionForm();
            }

            if (isTypeCheckEvent) {

                // Reset the county and locality fields
                this.$('#orderclientform-county-field').val('').trigger('chosen:updated');
                this._onCountyChange();
            }
        },

        /**
         * Event for changing the without email checkbox
         * @return {undefined}
         * @private
         */
        _onWithoutEmailCheck: function() {

            // todo add all these selectors into separate methods
            var $withoutEmailEl = this.$('#orderclientform-withoutEmail-field');
            var $emailEl = this.$('#orderclientform-email-field');
            var isChecked = $withoutEmailEl.length ? $withoutEmailEl.is(':checked') : false;

            if (isChecked) {

                // If it's checked add the default email
                $emailEl.val(this.DEFAULT_EMAIL);
                $emailEl.attr('disabled', true);

            } else {

                $emailEl.val('');
                $emailEl.attr('disabled', false);
            }
        },

        /**
         * Reset company section form
         * @private
         * @return {undefined}
         */
        _resetCompanySectionForm: function() {

            // todo add all these selectors into separate methods
            var $companyField     = this.$('#orderclientform-company-field');
            var $fiscalCodeField  = this.$('#orderclientform-fiscalCode-field');
            var $regNrField       = this.$('#orderclientform-regNr-field');
            var $vatField         = this.$('#orderclientform-vat-field');
            var $splitVatField    = this.$('#orderclientform-splitVat-field');
            var $addressField     = this.$('#orderclientform-address-field');

            // Reset the fields
            $companyField.val('');
            $fiscalCodeField.val('');
            $regNrField.val('');
            $addressField.val('');
            $vatField.removeAttr('checked');
            $splitVatField.removeAttr('checked');
        },

        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{name: *, tyoe: *,  company: *, fiscalCode: *, etc}}
         */
        _getFormData: function() {

            // todo add all these selectors into separate methods
            var $nameField         = this.$('#orderclientform-name-field');
            var $typeField         = this.$('#orderclientform-type-field');
            var $companyField      = this.$('#orderclientform-company-field');
            var $fiscalCodeField   = this.$('#orderclientform-fiscalCode-field');
            var $regNrField        = this.$('#orderclientform-regNr-field');
            var $phoneNumberField  = this.$('#orderclientform-phoneNumber-field');
            var $emailField        = this.$('#orderclientform-email-field');
            var $withoutEmailField = this.$('#orderclientform-withoutEmail-field');
            var $countyField       = this.$('#orderclientform-county-field');
            var $localityField     = this.$('#orderclientform-locality-field');
            var $addressField      = this.$('#orderclientform-address-field');
            var $bankField         = this.$('#orderclientform-bank-field');
            var $ibanField         = this.$('#orderclientform-iban-field');
            var $vatField          = this.$('#orderclientform-vat-field');
            var $splitVatField     = this.$('#orderclientform-splitVat-field');

            // It's a flag to know if we add the default email or not
            var isWithoutEmail = $withoutEmailField.length ? $withoutEmailField.is(':checked') : false;

            return {
                'name'        : $nameField.val().trim(),
                'type'        : $typeField.length ? $typeField.is(':checked') : false,
                'company'     : $companyField.val().trim(),
                'fiscalCode'  : $fiscalCodeField.val().trim().toUpperCase(),
                'regNr'       : $regNrField.val().trim(),
                'phoneNumber' : $phoneNumberField.val().trim(),
                'email'       : isWithoutEmail ? this.DEFAULT_EMAIL :  $emailField.val().trim(),
                'county'      : $countyField.val().trim(),
                'locality'    : $localityField.val().trim(),
                'address'     : $addressField.val().trim(),
                'bank'        : $bankField.val().trim(),
                'iban'        : $ibanField.val().trim(),
                'vat'         : $vatField.length ? $vatField.is(':checked') : false,
                'splitVat'    : $splitVatField.length ? $splitVatField.is(':checked') : false,
            };
        },

        /**
         * Display the success message
         * @param {string} message The message to be displayed
         * @returns {undefined}
         * @private
         */
        _showSuccess: function(message) {

            var $success = $('#orderinvoice-success-message');

            $success
                .removeClass('hidden')
                .text(message)
                .fadeTo('slow', 1)
                .delay(7000)
                .fadeTo('slow', 0, function(){
                    $success.addClass('hidden');
                }.bind(this));
        },

        /**
         * Method that sets the error messages.
         *
         * @param {string} message The message to be displayed
         * @private
         * @return {undefined}
         */
        _setErrorMsg: function(message){

            this.$errorMsg
                .text(message)
                .removeClass('hidden');
        },

        /**
         * Method that removes the error message.
         *
         * @private
         * @return {undefined}
         */
        _removeErrorMsg: function() {

            this.$errorMsg
                .addClass('hidden');
        },

        /**
         * Method that displays the modal window.
         *
         * @return {undefined}
         * @private
         */
        _displayClientModalForm: function() {

            this.$modalElement.modal("show");

            // this._resetForm();

            this._getClientData();

            // Focus on the first field
            //this._focusOnFirstField();
        },

        /**
         * Reset the form
         * @private
         * @return {undefined}
         */
        _resetForm: function() {

            var $form = this.$('#orderclient-modal-form');
            $form[0].reset();
        },

        /**
         * Get client data and prefill the form
         *
         * @return {undefined}
         * @private
         */
        _getClientData:  function() {

            var customer = this._getCustomer();

            if (!customer.hasOwnProperty('client')) {

                // If the client was not created yet, prefill the form from the customer data
                this._prefillFormFromCustomer();

            } else {

                // If the client was created, prefill the form from the model to edit the client
                this._prefillForm();
            }
        },

        /**
         * Prefill form from customer
         * @private
         * @return {undefined}
         */
        _prefillFormFromCustomer: function() {

            this._beforeEditEntity();

            var customer = this._getCustomer();
            var email = _.isUndefined(customer.email) ? '' : customer.email;

            this.model.set('name', customer.name);
            this.model.set('email', email);
            this.model.set('phoneNumber', customer.phoneNumber);
            this.model.set('county', customer.county.id);
            this.model.set('locality', customer.locality.id);
            this.model.set('address', customer.address);

            this._afterEditEntity();
        },

        /**
         * Prefill form
         * @private
         * @return {undefined}
         */
        _prefillForm: function() {

            this._beforeEditEntity();

            var client = this._getClient();
            var clientId = client.id;

            this.model.set('id', clientId);
            this._prepareModel(this.model);

            this.model.fetch({
                success: function(){

                    this.model.set('county', this.model.get('county').id);
                    this.model.set('locality', this.model.get('locality').id);

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this),
                error: function(){

                    this._afterEditEntity.apply(this, arguments);

                }.bind(this)
            });
        },

        /**
         * Method that hides the modal window.
         *
         * @return {undefined}
         * @private
         */
        _hideClientModalForm: function() {
            this.$modalElement.modal("hide");
        },

        /**
         * Method called before router edit entity.
         * @return {undefined}
         * @private
         */
        _beforeEditEntity: function() {

            this._isLoadingMask(true);
            this._isLoading(true, this.$("#orderclient-save-button"));
        },

        /**
         * Method called after router edit entity.
         * @return {undefined}
         * @private
         */
        _afterEditEntity: function() {

            this._isLoadingMask(false);
            this._isLoading(false, this.$("#orderclient-save-button"));

            this.render();
        },

        /**
         * Method that shows loader spinner button.
         *
         * @param {Object} loading - should show or hide the mask
         * @param {string} buttonSelector - button selector
         * @private
         * @return {undefined}
         */
        _isLoading: function(loading, buttonSelector) {

            loading = loading ? loading : _.isUndefined(loading);

            var $submitBtn   = this.$(buttonSelector);
            var $formFields  = this.$('fieldset');
            var ladda =  $submitBtn.data('ladda') ?  $submitBtn.data('ladda') :  $submitBtn.ladda().data('ladda');

            if( loading ){
                ladda.start();
                $formFields.attr('disabled', true);
            } else {
                ladda.stop();
                $formFields.removeAttr('disabled');
            }
        },

        /**
         * Show the loading mask for modal window.
         * @param {bool} loading - should show or hide the mask
         * @return {undefined}
         * @private
         */
        _isLoadingMask: function(loading) {

            loading = loading ? loading : _.isUndefined(loading);

            var $content = this.$modalElement.find('.modal-content.ibox-content');

            if( loading ){

                $content.addClass('sk-loading');

            } else {

                $content.removeClass('sk-loading');
            }
        },

        /**
         * Focus on the first field from the form modal.
         * If the first field is not an input, you can override this function in your view.
         *
         * @private
         * @return {undefined}
         */
        _focusOnFirstField: function() {

            this.$('fieldset').find('input:first').focus();
        },

        /**
         * Enable/disable or show/hide a form field
         * @param {object} $fields The field
         * @param {bool} display The type of toggle: true for display, false for state
         * @param {bool} override Override the toggle logic and turn it on or off by yourself
         * @returns {undefined}
         * @private
         */
        _toggle: function($fields, display, override) {

            $fields = _.isArray($fields) ? $fields : [$fields];

            _.each($fields, function($field){

                var show = function(){
                    $field.removeClass('hidden');
                };

                var hide = function(){
                    $field.addClass('hidden');
                };

                var enable = function(){
                    $field.removeAttr('disabled');
                };

                var disable = function(){
                    $field.attr('disabled', 'disabled');
                };

                var isToggled = _.isUndefined(override) ? this._isToggled($field, display) : !override;
                var toggleOn = display ? show : enable;
                var toggleOff = display ? hide : disable;

                if (isToggled) {

                    toggleOff();

                } else {

                    toggleOn();
                }

                $field.trigger('chosen:updated');

            }, this);
        },

        /**
         * Method that fills the form input fields with data coming from the model.
         * It is used when the form is being edited.
         *
         * @private
         * @return {undefined}
         */
        _fillForm: function() {

            var map = {
                "orderclientform-name-field": "name",
                "orderclientform-type-field": "type",
                "orderclientform-company-field": "company",
                "orderclientform-fiscalCode-field": "fiscalCode",
                "orderclientform-regNr-field": "regNr",
                "orderclientform-phoneNumber-field": "phoneNumber",
                "orderclientform-email-field": "email",
                "orderclientform-county-field": "county",
                "orderclientform-locality-field": "locality",
                "orderclientform-address-field": "address",
                "orderclientform-bank-field": "bank",
                "orderclientform-iban-field": "iban",
                "orderclientform-vat-field": "vat",
                "orderclientform-splitVat-field": "splitVat"
            };

            Object.keys(map).forEach(function(key) {

                this.$el.find("#" + key).val(this.model.get(map[key]));

                switch (key) {

                    case 'orderclientform-type-field':
                        this.$('#orderclientform-type-field').prop('checked', this.model.get(map[key]));
                        this._onTypeCheck();
                        break;

                    case 'orderclientform-vat-field':
                        this.$('#orderclientform-vat-field').prop('checked', this.model.get(map[key]));
                        break;

                    case 'orderclientform-splitVat-field':
                        this.$('#orderclientform-splitVat-field').prop('checked', this.model.get(map[key]));
                        break;

                    case 'orderclientform-county-field':

                        this.$('#orderclientform-county-field').trigger("chosen:updated");
                        this._onCountyChange();
                        break;

                    case 'orderclientform-locality-field':

                        // Load the locality field once
                        this.$('#orderclientform-locality-field').one('load', function() {

                            // Prefill the field
                            this.$('#orderclientform-locality-field').val(this.model.get('locality'));
                            this.$('#orderclientform-locality-field').trigger("chosen:updated");

                        }.bind(this));

                        break;

                    case 'orderclientform-bank-field':
                        this.$('#orderclientform-bank-field').attr('disabled', false).trigger("chosen:updated");
                        break;
                }

            }.bind(this));
        },

        /**
         * Displays the appropriate error based on response
         * @param {object} res The response
         * @return {undefined}
         * @private
         */
        _displayError: function(res) {

            this._removeErrorMsg();

            if (!res.responseJSON || !res.responseJSON.errors || res.responseJSON.errors.length === 0) {

                this._setErrorMsg(Translator.trans('js.error_message_orderclientform_error'));

                return;
            }

            var errors = res.responseJSON.errors;

            if (errors.length === 1) {

                var exception = _.first(errors).class;

                if (typeof exception !== 'undefined') {

                    var message = Translator.trans('js.error_message_orderclientform_error');

                    switch (exception) {

                        case 'EmagBundle\\Service\\Sap\\Exceptions\\Result':
                        case 'SAPNWRFC\\ConnectionException':

                            // Display the error message from SAP
                            message = _.first(errors).message;
                            this._setErrorMsg('[SAP ERROR] ' + message);
                            return;

                        case 'EmagBundle\\Exceptions\\AnafException':

                            // Display the error message from SAP
                            message = _.first(errors).message;
                            this._setErrorMsg('[ANAF ERROR] ' + message);
                            return;

                        default:
                            this._setErrorMsg(message);
                            return;
                    }
                }
            }

            _.each(errors, function(value) {

                if (value.field === "data.iban") {
                    this._setErrorMsg(Translator.trans('js.error_message_orderclientform_invalid_iban'));
                }

            }.bind(this));
        },

        /**
         * On searching the fiscal code
         * @private
         * @return {undefined}
         */
        _onSearchingFiscalCode: function() {

            var formData = this._getFormData();

            this.model.set(formData);

            // Validate the fiscal code
            if (!this.model.isValid('fiscalCode')) {
                return;
            }

            // Get company details
            this._getCompanyDetails(function(response, status) {

                if (status === 'error') {

                    response.responseJSON = JSON.parse(response.responseText);

                    this._displayError(response);
                }

                // Prefill the form with the details from the request
                this._fillCompanyForm();

                // Stop the loading
                this._isLoading(false, this.$("#orderclient-search-button"));

                this.$("#orderclient-save-button").attr('disabled', false);
            });
        },

        /**
         * Fill company form fields
         * @private
         * @return {undefined}
         */
        _fillCompanyForm: function() {

            var map = {
                "orderclientform-company-field"  : "company",
                "orderclientform-address-field"  : "address",
                "orderclientform-vat-field"      : "vat",
                "orderclientform-splitVat-field" : "splitVat"
            };

            Object.keys(map).forEach(function(key) {

                this.$el.find("#" + key).val(this.model.get(map[key]));

                switch (key) {

                    case 'orderclientform-vat-field':
                        this.$('#orderclientform-vat-field').prop('checked', this.model.get(map[key]));
                        break;

                    case 'orderclientform-splitVat-field':
                        this.$('#orderclientform-splitVat-field').prop('checked', this.model.get(map[key]));
                        break;
                }

            }.bind(this));
        },

        /**
         * Get the company details url
         * @return {string} the url
         * @private
         */
        _getCompanyDetailsURL: function() {

            return this.GET_ANAF_DETAILS_URL.replace('{fiscalCode}', this.model.get('fiscalCode'));
        },

        /**
         * Get company details
         * @param {function} callback function
         * @returns {undefined}
         * @private
         */
        _getCompanyDetails: function(callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var url = this._getCompanyDetailsURL();

            this._makeRequest(url, callback);

            this._isLoading(true, this.$("#orderclient-search-button"));
        },

        /**
         * On changing the fiscal code
         * @private
         * @return {undefined}
         */
        _onFiscalCodeChange: function() {

            // Disable the save button
            this.$("#orderclient-save-button").attr('disabled', true);
        },

        /**
         * Make ajax requests
         * @param {string} url request URL
         * @param {Function} callback callback funcyopm
         * @returns {undefined}
         * @private
         */
        _makeRequest: function(url, callback) {

            callback = _.isFunction(callback) ? callback : _.noop;

            var opts = {
                method      : 'GET',
                dataType    : 'html',
                contentType : 'text/plain'
            };

            z.request.make(url, null, function(jqXhr) {

                if (!jqXhr.hasOwnProperty('responseText')) {
                    return;
                }

                var response = JSON.parse(jqXhr.responseText);
                response = response.data;

                // Update the model with the details from ANAF
                this.model.set('address', response.address);
                this.model.set('company', response.name);
                this.model.set('vat', response.vat);
                this.model.set('splitVat', response.splitVat);
                this.model.set('active', response.active);

                callback.apply(this, arguments);

            }.bind(this), opts);
        },


    });

})(window.z = window.z || {}, Backbone, _);

