"use strict";

(function(z){

    z.ExtraServicesForm = z.FormView.extend({
        el:"#extraservicesform-modal",

        events: function() {

            return _.extend({}, z.FormView.prototype.events,{
                "click #extraservicesform-save-button": "_onSubmitForm",
            });
        },

        /**
         * Constructor.
         *
         * @return {undefined}
         */
        initialize: function() {

            z.FormView.prototype.initialize.apply(this, arguments);
        },

        /**
         * Method that renders the form
         *
         * @return {Object} - this object
         */
        render: function() {

            // Clean up the form
            this._cleanupForm();

            z.FormView.prototype.render.apply(this, arguments);

            // Initialize the chosen fields
            // this._initializeChosen();

            return this;
        },

        /**
         * Get add entity route name.
         *
         * @return {string} - add entity route name
         */
        getAddEntityRouteName: function() {
            return 'addExtraServices';
        },

        /**
         * Get edit entity route name.
         *
         * @return {string} - edit entity route name
         */
        getEditEntityRouteName: function() {
            return 'editExtraServices';
        },

        /**
         * Get modal form add title text
         *
         * @return {string} - modal form add title text
         */
        getModalFormAddTitleText: function() {
            return Translator.trans('js.btn_add_extraservices');
        },

        /**
         * Get modal form edit title text
         *
         * @return {string} - modal form edit title text
         */
        getModalFormEditTitleText: function() {
            return Translator.trans('js.btn_edit_extraservices');
        },

        /**
         * Get error panel container id.
         *
         * @return {string} - the error panel id
         */
        getErrorPanelId: function() {
            return '#extraservicesform-error-panel';
        },

        /**
         * Get submit button selector.
         *
         * @return {string} - the submit button selector
         */
        getSubmitButtonSelector: function() {
            return '#extraservicesform-save-button';
        },

        /**
         * Get page path.
         *
         * @return {string} - the page path
         */
        getPagePath: function() {
            return 'extraservices';
        },

        /**
         * Get success event name.
         *
         * @return {string} - the success event name
         */
        getSuccessEventName: function() {
            return 'extraservices.success';
        },

        /**
         * Get form error message text.
         *
         * @return {string} - form error message text
         */
        getFormErrorMessageText: function() {
            return Translator.trans('js.error_message_extraservicesform');
        },

        // /**
        //  * Set custom labels for the reseller modal form
        //  *
        //  * @param {string} action - action name
        //  * @return {undefined}
        //  */
        // setCustomLabels: function(action) {
        //
        //     // todo uncomment when API Details will be implemented
        //     switch (action) {
        //         case 'add':
        //             // this._modifyModalGenerateButton(Translator.trans('js.btn_generate'));
        //             break;
        //         case 'edit':
        //             // this._modifyModalGenerateButton(Translator.trans('js.btn_regenerate'));s
        //             break;
        //     }
        // },
        //
        /**
         * Collecting data from form inputs.
         *
         * @private
         * @return {Object} {{name: *, sapId: *, price: *, status: *}}
         */
        getFormData: function() {

            // Get the fields
            var $nameField     = this.$('#extraservicesform-name-field');
            var $sapIdField   = this.$('#extraservicesform-sapId-field');
            var $priceField   = this.$('#extraservicesform-price-field');
            var $statusField   = this.$('#extraservicesform-status-field');

            return {
                'name'     : $nameField.val(),
                'sapId'   : $sapIdField.val(),
                'price'   : $priceField.val(),
                'status'   : $statusField.val()
            };
        },
        //
        // /**
        //  * Method used to show a general error message if the user is
        //  * browsing the app on mobile
        //  *
        //  * @return {undefined}
        //  */
        // showMobileAlert: function()
        // {
        //     var errorMessage = Translator.trans('js.reseller_create_error_message_mobile');
        //
        //     // Display mobile alert
        //     this.$el.find('#reseller-create-general-error-panel')
        //         .removeClass('hidden')
        //         .text(errorMessage)
        //         .fadeTo('slow', 1)
        //         .delay(3000)
        //         .fadeTo('slow', 0, function(){
        //             this.hideMobileAlert();
        //         }.bind(this));
        // },
        //
        // /**
        //  * Method used to hide the error message if the form is submitted, or the modal has been closed
        //  *
        //  * @return {undefined}
        //  */
        // hideMobileAlert: function()
        // {
        //     this.$el.find('#reseller-create-general-error-panel').addClass('hidden');
        // },
        //
        /**
         * Method that resets the form fields, resets the collections and
         * hides the tables holding data
         *
         * @private
         * @return {undefined}
         */
        _cleanupForm: function() {

            // Resetting form
            this._resetFields("extraservicesform");
        },

        /**
         * Method that hides the wrapper that holds the table
         *
         * @param {Object} $wrapperName - the div object that acts as a wrapper
         * @private
         * @return {undefined}
         */
        _hideWrapper: function($wrapperName) {

            $wrapperName.addClass("hidden");
        },


        /**
         * Method that resets the specified fields according to id passed
         *
         * @param {string} value Name of the id to search for
         * @private
         * @return {undefined}
         */
        _resetFields: function (value) {

            this.$('[id^=' + value + ']').val("");
        },

        // /**
        //  * Method that modifies the generate button title
        //  *
        //  * @param {string} text - name of the action that is being performed
        //  * @private
        //  * @return {undefined}
        //  */
        // _modifyModalGenerateButton: function(text) {
        //
        //     var $modalGenerateButton = this.$('#extraservicesform-generate-button');
        //     $modalGenerateButton.text(text);
        // },
        //
        // /**
        //  * Method that fills the form input fields with data coming from the model.
        //  * It is used when the form is being edited.
        //  *
        //  * @private
        //  * @return {undefined}
        //  */
        _fillForm: function() {

            // this._fillShowroomForm(this.model);

            var map = {
                "extraservicesform-name-field": "name",
                "extraservicesform-sapId-field": "sapId",
                "extraservicesform-price-field": "price",
                "extraservicesform-status-field": "status"
            };

            Object.keys(map).forEach(function(key){

                this.$el.find("#"+key).val(this.model.get(map[key]));

            }.bind(this));
        },

        /**
         * Method that returns the text for the selected option
         *
         * @private
         * @return {*|jQuery} - Text for the selected status field option
         */
        // _getStatusValue: function() {
        //
        //     return this.$('#extraservicesform-status-field').find("option:selected").text();
        // },

        /**
         * Method that binds validation to the chosen model
         *
         * @param {object} model The model that the validation is being performend on
         * @private
         * @return {undefined}
         */
        _bindValidation: function(model) {

            model = _.isUndefined(model) ? this.model : model;
            Backbone.Validation.unbind(this, {model: model});

            Backbone.Validation.bind(this, {
                valid: this._onValid.bind(this),
                invalid: this._onInvalid.bind(this),
                model: model
            });
        },

        /**
         * Method that handles the form submission
         *
         * @param {Event} e - the event
         * @return {undefined}
         * @private
         */
        _onSubmitForm: function (e) {

            e.preventDefault();

            // Get form data
            var formData = this.getFormData();
            this.model.set(formData);

            var isValid = this.model.isValid(true);

            // Verify if the reseller model is valid
            if( !isValid ){
                this.showMobileAlert();
                return;
            }

            this._isLoading(true, this.getSubmitButtonSelector());
            // this._addShowroomsModel(this.model);

            // Save the reseller
            this.model.save(null, {

                success: function (i) {

                    this.model.trigger("success", i);
                }.bind(this)
            });
        },
    });

})(window.z = window.z || {});
