"use strict";

(function(z, Backbone){

    var ExtraServicesModel = Backbone.Model.extend({
        urlRoot: "/api/extraservices",
        validation: {
            name: [{
                required: true,
                msg: Translator.trans("js.error_message_extraservicesform_name_required")
            },{
                minLength: 1,
                msg: Translator.trans("js.error_message_extraservicesform_name_minlength")
            },{
                maxLength: 64,
                msg: Translator.trans("js.error_message_extraservicesform_name_maxlength")
            }],
            // price: [{
            //     required: true,
            //     msg: Translator.trans("js.error_message_extraservicesform_price_required")
            // },
            //     {
            //     minLength: 1,
            //     msg: Translator.trans("js.error_message_extraservicesform_price_minlength")
            // }, {
            //     pattern: "digits",
            //     msg: Translator.trans("js.error_message_extraservicesform_number_format")
            // }]
        },

        /**
         * Parses the response and returns the appropriate data
         * @param {Object} response The reponse
         * @return {Object} The data
         */
        parse: function(response){

            if( !_.isUndefined(response.data) ){

                return response.data;
            }

            return response;
        }
    });

    z.ExtraServicesModel = ExtraServicesModel;

})(window.z = window.z || {}, Backbone);
